import React from 'react'

import { CustomerPage } from '../components'

class Klanten extends React.Component {
  render() {
    return <CustomerPage />
  }
}

export default Klanten
